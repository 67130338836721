<script>

export default {
  name: "ItemTwo",
  data() {
    return {
      OSS_URL: process.env["VUE_APP_URL_OSS"],
    }
  },
  props: {
    title: String,
    value: String,
    img: Array
  }
}
</script>

<template>
  <div style="margin-bottom: 25rem">
    <div style="display: flex;padding-left: 16rem">
      <img :src="OSS_URL+'one-to-one/list.png'" alt="" style="width: 20rem;height: 20rem">
      <span style="font-size: 16rem;font-weight: normal;color: #1E3AA2;margin-left: 6rem">{{ title }}</span>
    </div>
    <div style="width: 1568rem;min-height: 136rem;border-radius: 8rem 8rem 8rem 8rem;border: 2rem solid #DCDFE6;margin-top: 16rem;padding: 15rem;box-sizing: border-box;font-size: 14rem;font-weight: normal;color: #666666;">
      <div>{{ value }}</div>
      <el-image v-for="(item, index) in img" :key="index" :src="item" alt="" style="width: 120rem;height: 120rem;margin-right: 24rem;margin-top: 8rem" :preview-src-list="[item]"></el-image>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
