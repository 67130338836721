<script>
export default {
  name: "Item",
  props: {
    label: String
  }
}
</script>

<template>
  <div style="display: flex" :title="label">
    <div style="width: 3rem;height: 24rem;background: #1C4EFD;border-radius: 92rem 92rem 92rem 92rem;"></div>
    <div class="item-text">
      {{ label }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.item-text {
  font-size: 14rem;
  font-weight: 400;
  line-height: 24rem;
  overflow: hidden;
  width: 135rem;
  height: 24rem;
  padding: 0 8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #1E3AA2;
  border-radius: 2rem 2rem 2rem 2rem;
  background: #FFF;
}
</style>
