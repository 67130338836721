<template>
  <div style="background: #F9FAFD;min-width: 1612px;display: flex;flex-flow: column;align-items: center">
    <div class="box" style="display: flex;background: #F9FAFD;padding: 0 12px;box-sizing: border-box">
      <div>
        <div class="text">辅导对象</div>
        <div class="box_1 public-box">
          <img :src="OSS_URL+'one-to-one/3.png'" alt="" class="img">
          <div style="margin: 23px 32px 0;display: flex;align-items: center;justify-content: space-between">
            <div class="item-text" style="width: 170px;height: 39px;background: linear-gradient(270deg, rgba(28,78,253,0.57) 0%, rgba(28,78,253,0.81) 100%);border-radius: 8px 0 0 8px">
              <img :src="OSS_URL+'one-to-one/students.png'" alt="" style="width: 24px;height: 24px;margin-right: 20px">
              <div style="width: 112px;height:26px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ form.student_name }}</div>
            </div>
            <img :src="OSS_URL+'one-to-one/list-item.png'" alt="" style="width: 32px;height: 32px">
            <div class="item-text" style="width: 170px;height: 39px;background: linear-gradient(270deg, rgba(28,78,253,0.81) 0%, rgba(28,78,253,0.57) 100%);border-radius: 0 8px  8px 0">
              <img :src="OSS_URL+'one-to-one/teacher.png'" alt="" style="width: 24px;height: 24px;margin-right: 20px">
              <div style="width: 112px;height:26px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ form.teacher_name }}</div>
            </div>
          </div>
          <div style="margin-top: 24px;padding-left: 39px;box-sizing: border-box">
            <div style="display: flex;margin-bottom: 16px">
              <Item :label="form.student_no"/>
              <div style="margin-left: 118px">
                <Item :label="form.subject_name"/>
              </div>
            </div>
            <Item :label="form.class_name"/>
          </div>
        </div>
      </div>

      <div>
        <div class="text">学生日常表现</div>
        <div class="box_2 public-box" style="display: flex; flex-wrap: wrap; box-sizing: border-box; align-items: center; padding: 5px; justify-content: center">
          <img :src="OSS_URL+'one-to-one/1.png'" alt="" class="img">
          <div v-for="(item, index) in form.daily_performance" :key="index" style="display: flex; height: 32px; margin: 0 5px;" >
            <img :src="OSS_URL+'one-to-one/tag.png'" alt="" style="width: 15px; height: 32px;">
            <div style="height: 32px; background: #5DA8FE; border: 1px solid rgba(255, 255, 255, 0.5); border-left: none; font-size: 14px; font-weight: 400; color: #FFFFFF; line-height: 32px; padding: 0 10px; max-width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" :title="item">
              {{ item }}
            </div>
          </div>
        </div>

      </div>

      <div>
        <div class="text">学生特性</div>
        <div class="box_3 public-box" style="display: flex;align-items: center;justify-content: space-between;padding: 0 36px;box-sizing: border-box">
          <img :src="OSS_URL+'one-to-one/2.png'" alt="" class="img">
          <div v-for="(key, index) of form.student_property" style="text-align: center;">
            <div class="text_3">{{ index }}</div>
            <div class="text_2">{{ key }}</div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 1616px;min-height: 418px;background: #FFFFFF;border-radius: 8px 8px 8px 8px;margin-top: 25px;padding: 24px;box-sizing: border-box">
      <ItemTwo :value="form.msg_to_student" title="学生基本情况"/>
      <ItemTwo :value="form.summary" title="本次辅导内容&下次辅导计划" :img="form.image" />
      <!--      <ItemTwo title="下次辅导计划"/>-->
    </div>

    <div style="width: 1616px;min-height: 168px;background: #FFFFFF;border-radius: 8px 8px 8px 8px;margin-top: 24px;padding: 0 10px;box-sizing: border-box;display: flex;flex-flow: column">
      <div style="font-size: 20px;font-weight: normal;color: #1E3AA2;line-height: 54px;border-bottom: 1px solid #F9FAFD">
        <span style="margin-left: 14px">辅导明细</span>
      </div>
      <div style="padding: 0 14px;box-sizing: border-box;display: flex;flex: 1;flex-wrap: wrap;min-height: 100%">
        <div class="time" style="display: flex;">
          <div style="width: 104px;font-size: 16px;font-weight: 400;color: #999999;">辅导时间</div>
          <div style="height: 18px;font-size: 16px;font-weight: 500;color: #666666;margin-right: 72px">{{ form.plan_start_end_time }}</div>
        </div>
        <div class="school" style="display: flex">
          <div style="width: 72px;font-size: 16px;font-weight: 400;color: #999999;">校区</div>
          <div style="height: 18px;font-size: 16px;font-weight: 500;color: #666666;margin-right: 72px">{{ form.school_name }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 136px;font-size: 16px;font-weight: 400;color: #999999;">任务创建时间</div>
          <div style="height: 18px;font-size: 16px;font-weight: 500;color: #666666;margin-right: 72px">{{ form.task_create_at }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 136px;font-size: 16px;font-weight: 400;color: #999999;">单人填写时间</div>
          <div style="height: 18px;font-size: 16px;font-weight: 500;color: #666666;">{{ form.status_at }}</div>
        </div>
        <div :style="{minWidth: width+'px', marginRight: width2 > 369 ? '36px' : '0'}" style="display: flex" class="full_addr_name">
          <div style="min-width: 104px;font-size: 16px;font-weight: 400;color: #999999;">辅导地点</div>
          <div style="font-size: 16px;font-weight: 500;color: #666666;">{{ form.full_addr_name }}</div>
        </div>
        <div :style="{width: width1+'px'}" style="display: flex;">
          <div style="width: 72px;font-size: 16px;font-weight: 400;color: #999999;">年级</div>
          <div style="height: 18px;font-size: 16px;font-weight: 500;color: #666666;">{{ form.grade_name }}</div>
        </div>
        <div style="display: flex">
          <div style="width: 136px;font-size: 16px;font-weight: 400;color: #999999;">完成填写时间</div>
          <div style="height: 18px;font-size: 16px;font-weight: 500;color: #666666;">{{ form.teacher_score_at }}</div>
        </div>
      </div>
    </div>
    <div style="width: 100%;margin-top: 24px;padding-left: 18px">
      <el-button @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>

<script>
import Item from "@/views/edu/one-on-one-tutoring/new-coaching-records/component/Item.vue";
import ItemTwo from "@/views/edu/one-on-one-tutoring/new-coaching-records/component/ItemTwo.vue";


export default {
  _config:{
    route:{
      path:"look",
      meta:{"title":"查看","type":1},

    }
  },
  name: "look",
  data() {
    return {
      OSS_URL: process.env["VUE_APP_URL_OSS"],
      form: {},
      width: 100,
      width1: 100,
      width2: 100,
      arr:[30,30,20,20],
    }
  },
  components: {
    ItemTwo,
    Item,
  },
  watch: {
    form: {
      handler() {
        this.$nextTick(() => {
          this.width = document.getElementsByClassName('time')[0].offsetWidth
          this.width1 = document.getElementsByClassName('school')[0].offsetWidth
          this.width2 = document.getElementsByClassName('full_addr_name')[0].offsetWidth
          console.log(this.width2, '----------------')
        })
      },
      deep: true
    }
  },

  created() {
    this.$_axios2.get('api/coach-record/detail', {params: {id: this.$route.query.id}}).then(res => {
      this.form = res.data.data
      // for (let formKey in this.form) {
      //   console.log(formKey + ':' + this.form[formKey])
      // }
    })
  }
}
</script>

<style lang="scss" scoped>
.box_1 {
  min-width: 489px;
  max-width: 489px;
  height: 170px;
  opacity: 1;
  border: 2px solid #6386FF;
  border-radius: 4px 4px 4px 4px;
  background: #F6F9FF;
}

.box_2 {
  min-width: 450px;
  max-width: 450px;
  height: 171px;
  margin: 0 24px;
  opacity: 1;
  border: 2px solid #5DA8FE;
  border-radius: 4px 4px 4px 4px;
  background: #F6F9FF;
}

.box_3 {
  min-width: 630px;
  max-width: 630px;
  height: 170px;
  opacity: 1;
  border: 2px solid #FFC052;
  border-radius: 4px 4px 4px 4px;
  background: #FFFDFB;
}

.public-box {
  position: relative;
}

.text {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 18px;
  margin-left: 12px;
  color: #1E3AA2;
}

.img {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
}

.item-text {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 14px;
  color: #FFFFFF;
}

.text_2 {
  font-size: 20px;
  font-weight: normal;
  color: #F8A615;
  margin-top: 34px;
}

.text_3 {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
</style>
